import React, { useState, useEffect } from 'react';
import * as queryString from 'query-string';
import QRCode from 'qrcode.react';

import loader from '../images/jobs/loader.svg';
import image from '../images/pages/emailVerification.png';
import Layout from '../components/Layout';
import MetaData from '../components/meta/MetaData';
import { extLinkClick } from '../utils/general';
import {
	ReferralFooter,
	Headline,
	ReferralOption,
	ReferralHeadline,
	ReferralContainer,
	CrowdLink,
	ReferralHeader,
	ReferralWrapper,
	ReferralTitle,
	ReferralBody,
	Wrapper,
	ReferralImage,
	Loader,
	ModalSpinner,
} from '../styles';

const MobileLink = ({ location }) => {
	const [load, setLoad] = useState(true);
	const { uri } = queryString.parse(location.search);

	useEffect(() => {
		setTimeout(() => {
			setLoad(false);
		}, 1500);
	}, []);

	return (
		<Layout>
			<MetaData title="Envel - Mobile Link" description="Mobile links for Envel app" image="images/social/social.png" />

			{load ? (
				<ModalSpinner>
					<Loader src={loader} />
				</ModalSpinner>
			) : uri ? (
				<>
					<ReferralHeader center>Mobile Link</ReferralHeader>
					<ReferralHeadline center>To use the mobile link please follow one of the options below</ReferralHeadline>

					<ReferralWrapper row justifyAround alignCenter>
						<ReferralBody className="top">
							<ReferralOption>OPTION 1</ReferralOption>
							<ReferralTitle>Scan The QR Code</ReferralTitle>
							<Headline>Use your mobile phone to scan the QR code and be directed to the app.</Headline>
						</ReferralBody>
						<QRCode value={`https://envel.app.link/${uri}`} size={200} bgColor="#00000000" fgColor="#fff" />
					</ReferralWrapper>

					<ReferralWrapper row justifyAround alignCenter>
						<ReferralBody className="big">
							<ReferralOption>OPTION 2</ReferralOption>
							<ReferralTitle>Open The Link From Your Mobile</ReferralTitle>
							<Headline>
								Use your mobile phone to tap the link{' '}
								<CrowdLink
									onClick={() => extLinkClick(`https://envel.app.link/${uri}`)}
								>{`envel.app.link/${uri}`}</CrowdLink>
								{'. '}
								You will then be directed to the Envel app.
							</Headline>
						</ReferralBody>
					</ReferralWrapper>

					<ReferralFooter center>
						Please contact us if this is not working for you on{' '}
						<a href="mailto:support@envel.ai" target="_blank" rel="noopener noreferrer">
							support@envel.ai
						</a>{' '}
						or via the chat on the Envel app.
					</ReferralFooter>
				</>
			) : (
				<>
					<Wrapper>
						<ReferralImage src={image} />
					</Wrapper>
					<ReferralHeader center>Mobile Link</ReferralHeader>
					<ReferralHeadline center>
						In order to access the Envel App link actions, please open the link on your mobile phone.
					</ReferralHeadline>
				</>
			)}
		</Layout>
	);
};

export default MobileLink;
